<template>
    <modal ref="modalAgregarEquipo" tamano="modal-lg" titulo="Crear oportunidad" adicional="Crear" @adicional="crearOp">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-6 my-2">
                <p class="input-label-top">Cliente</p>
                <el-select v-model="valueSelect" placeholder="Seleccionar cliente" size="small" class="w-100">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Nombre de la oportunidad</p>
                <el-input v-model="inputNombreOp" class="w-100" size="small" />
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Tipo de oportunidad</p>
                <el-select v-model="valueSelect" placeholder="Seleccionar tipo" size="small" class="w-100">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Responsable Principal</p>
                <el-select v-model="valueSelect" placeholder="Seleccionar responsable" size="small" class="w-100">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="col-4 my-3">
                <p class="input-label-top">Fecha de identificación</p>
                <el-date-picker v-model="pickerFechaInicio" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
            </div>
            <div class="col-4 my-3">
                <p class="input-label-top">Fecha de cierre</p>
                <el-date-picker v-model="pickerFechaCierre" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
            </div>
            <div class="col-4 my-3">
                <p class="input-label-top">Probabilidad</p>
                <el-input v-model="inputProbabilidad" class="w-100" placeholder="Valor %" size="small" />
            </div>
            <div class="col-12 my-2">
                <p class="input-label-top">Descripción</p>
                <el-input v-model="inputDescripcion" type="textarea" :autosize="{ minRows: 3}" class="f-12 br-5 w-100" size="small" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            inputNombreOp: '',
            inputProbabilidad: '',
            pickerFechaInicio: '',
            pickerFechaCierre: '',
            inputProbabilidad: '',
            inputDescripcion: '',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAgregarEquipo.toggle()
        },
        crearOp(){
            this.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
